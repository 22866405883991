import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Button, Modal, ListGroup, Spinner } from 'react-bootstrap';
import './ConversationDetails.css';

const apiUrl = process.env.REACT_APP_API_URL;

const ConversationDetails = () => {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedString, setSelectedString] = useState('');
  const [strings, setStrings] = useState([
    'Dzień dobry, niestety już sprzedane. Ogłoszenie zostanie automatycznie usuniete jak kupujący odbierze paczkę. \n' +
    'Zapraszam do obserowania profilu - często pojawia się nowy asortyment w dobrych cenach. A cena każdego przedmiotu będzie codziennie obniżana o 10 złotych, zaczynając tydzień po ukazaniu się ogłoszenia. Zachęcamy do obserwowania, bo ktoś może Cię ubiec!',
    'Dzień dobry, cieszę się za zaintersowanie naszą ofertą. Cena jest już bardzo atrakcyjna - o czym wiadomo przeglądając inne ogłoszenia na OLX tego produktu. W związku z tym nie możemy już nic opuścić. Ewentualnie symboliczna kwota na przesyłkę. Cena przedmiotu będzie codziennie obniżana o 10 złotych, zaczynając tydzień po ukazaniu się ogłoszenia. Zachęcamy do obserwowania, bo ktoś może Cię ubiec!',
    'Dzień dobry, cieszę się za zaintersowanie naszą ofertą. Cena jest już bardzo atrakcyjna - o czym wiadomo przeglądając inne ogłoszenia na OLX tego produktu. W związku z tym nie możemy już nic opuścić. Zakup jest realizowany w firmie - kupujący zawsze otrzymuje paragon lub fakturę. Cena przedmiotu będzie codziennie obniżana o 10 złotych, zaczynając tydzień po ukazaniu się ogłoszenia. Zachęcamy do obserwowania, bo ktoś może Cię ubiec!',
      'Niektóre są powystawowe, niektóre słuzyły do testowania oprogramowania. Nie mamy szczegółowych informacji. Stan jest opisany w ogłoszeniu. Zachęcam do odbioru osobistego we Wrocławiu - można zobaczyć na miejscu.',
      'Cena została zmieniona. Zaraz ogłoszenie się odświeży z nową ceną. Dopiero po odświeżeniu i widocznej nowej cenie proszę dokonać zakupu.',
      "Ogólnie prowadzimy sprzedaż przez internet. Jednak jeżeli klient chciałby zobaczyć to mogę przywieść z magazynu wybrany towar do domu i można się umowić - Wrocław ul. Dmowskiego"
  ]); // Przykładowe stringi
  const [aiSuggestion, setAiSuggestion] = useState(null);

  const fetchConversationDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/conversations/${id}`);
      setItem(response.data);
    } catch (error) {
      console.error('Error fetching conversation details:', error);
    }
    setLoading(false);
  };

  const fetchAiSuggestion = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/conversations/${id}/ai`);
      setAiSuggestion(response.data.suggestion);
    } catch (error) {
      console.error('Error fetching AI suggestion:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchConversationDetails();
  }, [id]);

  const handleStringClick = async (selectedString) => {
    setLoading(true);
    setSelectedString(selectedString);
    try {
      await axios.put(`${apiUrl}/conversations/${id}`, { selectedString });
      setShowModal(false);
      fetchConversationDetails(); // Opcjonalnie: odśwież dane po aktualizacji
    } catch (error) {
      console.error('Error updating conversation:', error);
    }
    setLoading(false);
  };

  if (!item) {
    return <div>Loading...</div>;
  }

  const messages = [...item.conversation.messages].reverse();

  return (
    <div>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Show Modal
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select a String</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="secondary" onClick={fetchAiSuggestion} disabled={loading}>
            {loading ? 'Loading...' : 'Get AI Suggestion'}
          </Button>
          <ListGroup>
            {aiSuggestion && (
              <ListGroup.Item style={{ backgroundColor: 'red', color: 'white' }} onClick={() => handleStringClick(aiSuggestion)}>
                {aiSuggestion}
              </ListGroup.Item>
            )}
            {strings.map((string, index) => (
              <ListGroup.Item key={index} onClick={() => handleStringClick(string)}>
                {string}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>

      <h2>{item.conversation.respondent.name}</h2>
      <h3>{item.conversation.ad.title}</h3>
      {item.conversation.selected_string && (
        <p><strong>Selected String:</strong> {item.conversation.selected_string}</p>
      )}
      <div>
        {messages.map((message, index) => (
          <div key={index}>
            <p><strong>{message.user_id}</strong>: {message.text}</p> {/* Renderowanie tekstu wiadomości */}
            <p>{new Date(message.created_at).toLocaleString()}</p> {/* Renderowanie daty wiadomości */}
          </div>
        ))}
      </div>

      {loading && (
        <div className="loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
};

export default ConversationDetails;
